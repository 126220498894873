import React from 'react';
// eslint-disable-next-line import/prefer-default-export
export const balance = [
  {
    id: 0,
    icon: '/img/careers/1.svg',
    heading: 'Comprehensive Insurance',
    description: (
      <>
        Health insurance for employees, partners and families for all medical
        situations, including the current pandemic. Insurance coverage also
        extends to LGBTQIA+&nbsp;partners.
      </>
    ),
  },
  {
    id: 1,
    icon: '/img/careers/2.svg',
    heading: 'Flexible Timings',
    description: (
      <>
        A conducive and inclusive work environment, whether you’re an early bird
        or a night owl. Work when you work best. Find the productivity rhythm
        that suits you&nbsp;best.
      </>
    ),
  },
  {
    id: 2,
    icon: '/img/careers/3.svg',
    heading: 'Conducive Learning Environment',
    description: (
      <>
        Constantly upskill to keep your spark alive with our expansive learning
        budgets and support. Learning resources also include your
        knowledgeable&nbsp;peers!
      </>
    ),
  },

  {
    id: 3,
    icon: '/img/careers/4.svg',
    heading: 'Generous Leave Policy',
    description: (
      <>
        When life gets in the way, we’ve still got your back. Unlimited wellness
        and COVID leaves to help you balance life, work and everything
        in&nbsp;between.
      </>
    ),
  },
];

export const ourPeople = [
  {
    id: 0,
    image: '/img/careers/gauri-singh.jpg',
    name: 'Gauri Singh',
    designation: 'Senior Product Manager',
    quote:
      'Joining Cashfree Payments wasn’t a difficult decision at all - I’d heard so much about their culture! From collaborating across teams to being given the freedom to experiment, I have received immense support from the organisation towards growing into my best professional self',
  },
  {
    id: 1,
    image: '/img/careers/harshitha-rao.jpg',
    name: 'Harshitha Rao',
    designation: 'Senior Manager, Brand Marketing',
    quote:
      'My journey at Cashfree Payments has been very enriching. The opportunity I’ve had to learn and grow, and collaborate with highly experienced peers has given me so much to look forward to. I’m sure to find myself reaching new heights as the company keeps growing',
  },
  {
    id: 2,
    image: '/img/careers/heena-dasaur.jpg',
    name: 'Heena Dasaur',
    designation: 'Senior Manager, HR Business Partnering',
    quote:
      'We wear multiple hats, we get the freedom to try new things, and we’re never short of opportunities - that’s how I’d describe working at Cashfree Payments. A culture with no hierarchies or micromanagement and the constant motivation to go beyond the call of duty are what make the culture here as great as it is. One year down, I couldn’t be happier!',
  },
  {
    id: 3,
    image: '/img/careers/om-pandey.jpg',
    name: 'Om Pandey',
    designation: 'Nodal Officer',
    quote:
      'As part of the risk team, every day brings a new challenge. Preventing fraud, making anti-bribery policies, and fighting terrorist financing is a thrilling job, after all! What I love the most is the way we collaborate and find creative solutions to ever-evolving problems. And, knowing that our merchants are safe and our systems are robust at the end of the day, is super rewarding. Love my job, love the people, love Cashfree!',
  },
  {
    id: 4,
    image: '/img/careers/radhika-jain.jpg',
    name: 'Radhika Jain',
    designation: 'Nodal Officer',
    quote:
      'Five years ago, I joined as the 18th employee here at Cashfree Payments. I have since worked CS and Operations and have had the opportunity to understand the fintech and payments industry in its full glory - from the very basics to the minutest of details. So glad I could be a part of Cashfree’s stellar growth journey!',
  },
];

export const galleryData = [
  { id: 0, src: 'slider-1.jpg', width: '916', height: '518', alt: '' },
  { id: 1, src: 'slider-2.jpg', width: '566', height: '518', alt: '' },
  { id: 2, src: 'slider-3.jpg', width: '566', height: '518', alt: '' },
  { id: 3, src: 'slider-4.jpg', width: '566', height: '518', alt: '' },
  { id: 4, src: 'slider-5.jpg', width: '566', height: '518', alt: '' },
  { id: 5, src: 'slider-6.jpg', width: '916', height: '518', alt: '' },
  { id: 6, src: 'slider-7.jpg', width: '916', height: '518', alt: '' },
  { id: 7, src: 'slider-8.jpg', width: '566', height: '518', alt: '' },
  { id: 8, src: 'slider-9.jpg', width: '566', height: '518', alt: '' },
  { id: 9, src: 'slider-10.jpg', width: '566', height: '518', alt: '' },
  { id: 10, src: 'slider-11.jpg', width: '566', height: '518', alt: '' },
  { id: 11, src: 'slider-12.jpg', width: '916', height: '518', alt: '' },
];
export const mobileData = [
  {
    id: 0,
    src: 'slider-1-mb.jpg',
    width: '686',
    height: '352',
    alt: '',
    span: 2,
  },
  {
    id: 1,
    src: 'slider-2-mb.jpg',
    width: '330',
    height: '352',
    alt: '',
    span: 1,
  },
  {
    id: 2,
    src: 'slider-3-mb.jpg',
    width: '330',
    height: '352',
    alt: '',
    span: 1,
  },
  {
    id: 3,
    src: 'slider-4-mb.jpg',
    width: '330',
    height: '352',
    alt: '',
    span: 1,
  },
  {
    id: 4,
    src: 'slider-5-mb.jpg',
    width: '330',
    height: '352',
    alt: '',
    span: 1,
  },
  {
    id: 5,
    src: 'slider-6-mb.jpg',
    width: '686',
    height: '352',
    alt: '',
    span: 2,
  },
  {
    id: 6,
    src: 'slider-7-mb.jpg',
    width: '330',
    height: '352',
    alt: '',
    span: 1,
  },
  {
    id: 7,
    src: 'slider-8-mb.jpg',
    width: '330',
    height: '352',
    alt: '',
    span: 1,
  },
  {
    id: 8,
    src: 'slider-9-mb.jpg',
    width: '330',
    height: '352',
    alt: '',
    span: 1,
  },
  {
    id: 9,
    src: 'slider-10-mb.jpg',
    width: '330',
    height: '352',
    alt: '',
    span: 1,
  },
  {
    id: 10,
    src: 'slider-11-mb.jpg',
    width: '686',
    height: '352',
    alt: '',
    span: 2,
  },
];

import { useEffect, useState } from 'react';

const PAGE_SIZE = 10;
const sliceData = (page = 1, data = []) => data.slice(0, PAGE_SIZE * page);

function usePagination(initalData = []) {
  const [page, setPage] = useState(1);
  const loadMore = () => setPage((prev) => prev + 1);
  const resetPagination = () => setPage(1);
  const hasMore = page * PAGE_SIZE < initalData.length;

  useEffect(() => {
    setPage(1);
  }, [initalData]);
  return {
    data: sliceData(page, initalData),
    hasMore,
    loadMore,
    resetPagination,
  };
}

export default usePagination;

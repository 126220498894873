import { useState, useCallback, useMemo } from 'react';

export const defaultFilters = {
  text: '',
  department: '',
  location: '',
};

const transformOption = (v, index) => ({
  id: index,
  label: v,
  value: v,
});

function useJobs(defaultJobs) {
  const [filters, setFilters] = useState(defaultFilters);
  const onChange = useCallback((key, value) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  }, []);

  const jobs = useMemo(
    () =>
      (defaultJobs || [])
        .filter((j) =>
          filters.department !== ''
            ? j?.department === filters.department
            : true,
        )
        .filter((j) =>
          filters.location !== '' ? j?.location === filters.location : true,
        )
        .filter(
          (j) =>
            (j.searchText || '')
              .toLocaleLowerCase()
              .includes((filters.text || '').toLocaleLowerCase()) > 0,
        ),
    [filters, defaultJobs],
  );
  const defaults = useMemo(
    () => ({
      department: Array.from(
        new Set(
          (defaultJobs || [])
            .map((j) => j.department || '')
            .filter((j) => j != null && j.length !== 0),
        ),
      ).map(transformOption),
      location: Array.from(
        new Set(
          (defaultJobs || [])
            .map((j) => j.location || '')
            .filter((j) => j != null && j.length !== 0),
        ),
      ).map(transformOption),
    }),
    [defaultJobs],
  );

  return {
    filters,
    onChange,
    jobs,
    defaults,
  };
}

export default useJobs;

import React, { useRef } from 'react';
import GetStarted from '../components/GetStarted';
import JobSearch from '../components/Jobs/JobSearch';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import GallerySlider from '../components/Sliders/GallerySlider/GallerySlider';
import PeopleQuoteSlider from '../components/Sliders/PeopleQuoteSlider/PeopleQuoteSlider';
import TransparentVideo from '../components/TransparentVideo/TransparentVideo';
import {
  balance,
  galleryData,
  mobileData,
  ourPeople,
} from '../content/careers';
import '../styles/careers.scss';

const CareersPage = (props) => {
  const jobRef = useRef();
  const container = useRef();
  const scrollToJob = () => {
    if (jobRef.current && window && window.scroll) {
      window?.scroll({
        top: jobRef.current?.offsetTop ?? 0,
        behavior: 'smooth',
      });
    }
  };
  return (
    <Layout
      lightHeader
      {...props}
      signInLink="https://merchant.cashfree.com/merchants/login?source-action=Careers&action=Sign%20In&button-id=SignIn_Navbar"
      createAccountLink="https://merchant.cashfree.com/merchants/signup?source-action=Careers&action=Sign%20Up&button-id=StartNow_Navbar">
      <SEO
        title="Discover Your Career Path | Explore Job Opportunities at Cashfree Payments"
        description="Join our team and find your dream job at Cashfree Payments. Discover exciting career paths and job opportunities in Fintech Industry. Explore our Careers page to learn more."
        url="https://www.cashfree.com/careers/"
      />

      <div className="pt-[110px] md:pt-[150px] pb-[36px] md:pb-[100px] bg-cf-hero text-white relative careers-hero overflow-hidden">
        <div className="container">
          <div className="flex flex-wrap">
            <div className="w-full md:w-7/12">
              <div className="text-sm font-semibold text-white pl-5 mb-[10px] uppercase relative before:bg-cf-green before:h-[10px] before:w-[10px] before:absolute before:top-[4px] before:left-[4px] font-body">
                Careers at Cashfree
              </div>
              <div className="text-xl md:text-2xl font-semibold max-w-[350px] sm:max-w-full lg:max-w-[80%] font-heading mb-3 md:mb-5  relative z-[1]">
                Come, help build the payments infrastructure of&nbsp;tomorrow!
              </div>
              <div className="text-[16px] leading-[24px] md:text-2.5md mb-6 md:mb-10  relative z-[1] md:max-w-[90%]">
                Cashfree Payments is India’s leading payments and API banking
                company, powering over 150k+ businesses with their end-to-end
                payment needs.
              </div>
              <button
                className="button button-green bg-cf-green w-full md:w-auto mt-2 md:mt-0 hover:brightness-110 active:brightness-110 focus:brightness-110"
                type="button"
                onClick={scrollToJob}>
                Explore Jobs
              </button>
            </div>
            <div className="w-full md:w-5/12 md:pl-[19px] relative">
              <div className="pt-12 z-10 md:pt-0 max-w-[112%] w-[112%] left-[-6%] relative block md:hidden md:absolute md:bottom-[-130px]">
                <TransparentVideo
                  backgroundRef={container}
                  className="relative z-10"
                  poster="/img/careers/poster-mb.webp"
                  width="1392"
                  height="1560"
                  mp4Src="/img/careers/hero-video-mb.mp4"
                  webmSrc="/img/careers/hero-video-mb.webm"
                />
              </div>
              <div className="pt-12 z-10 md:pt-0 max-w-[120%] md:max-w-[696px] md:w-[696px] hidden md:block md:absolute md:bottom-[-130px]">
                <TransparentVideo
                  backgroundRef={container}
                  className="relative z-10"
                  poster="/img/careers/poster.webp"
                  width="1392"
                  height="1042"
                  mp4Src="/img/careers/hero-video.mp4"
                  webmSrc="/img/careers/hero-video.webm"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="relative p-section-2  bg-cf-light-grey overflow-hidden careers-people-section">
        <div className="container">
          <div className="text-center max-w-[620px] mx-auto">
            <div className="font-semibold  font-heading text-shs md:text-shl mb-3 md:mb-4">
              We believe in our people
            </div>

            <p className="text-[14px] leading-[19px] md:text-base">
              At Cashfree Payments, we are all heart and soul. We truly believe
              that we grow personally and professionally with great culture
              and&nbsp;people.
              <br />
              <br />
              We choose the right people, provide resources to train them, and
              also focus on retaining them - so they have all the support that
              sets them up for&nbsp;success.
              <br />
              <br />
              <b> We own what we do. Even when we fail.</b>
              <br />
              <br />
              We also encourage our people to move out of their comfort zone to
              find new and creative ways to solve problems. And, this is how we
              have been able to{' '}
              <span className="md:block">
                create our <b>Dream&nbsp;Team</b>.
              </span>
            </p>
          </div>
        </div>
      </section>
      <section className="p-section-3 relative" ref={jobRef}>
        <div className="container">
          <div className="font-heading text-lg md:text-xl font-semibold mb-[40px] text-center">
            View all open roles
          </div>
          <JobSearch />
        </div>
      </section>
      <section className="relative py-[48px] md:pt-[96px] md:pb-[128px] bg-cf-light-grey">
        <div className="container">
          <div className="font-heading text-lg md:text-xl font-semibold mb-[40px] text-center">
            Hear from our people
          </div>
          <PeopleQuoteSlider data={ourPeople} />
        </div>
      </section>
      <section className="overflow-hidden pb-[48px] md:pb-[128px] bg-cf-light-grey">
        <GallerySlider
          data={galleryData}
          imgUrl="/img/careers"
          mobileData={mobileData}
        />
      </section>
      <section className="relative bg-cf-dark p-section-3 career-build-section overflow-hidden">
        <div className="container">
          <div className="flex flex-wrap justify-between">
            <div className="w-full md:w-1/2">
              <div className="text-2lg font-heading mb-4 text-white">
                We build the{' '}
                <span className="block md:inline">best-in-class tech</span>
              </div>
              <div className="text-md text-cf-cold-purple">
                <p>
                  Our payments stack powers hundreds and thousands of payments
                  every second, across the entire country and beyond. For this
                  to materialise seamlessly, we constantly ensure we have the
                  best minds on the job.
                  <br />
                  <br />
                  Our developers are some of the best in the industry, who push
                  the boundaries to help businesses save time, efforts, and
                  money. They do this by constantly learning and employing the
                  latest technology to make the lives of business owners and
                  other stakeholders, much easier.
                  <br />
                  <br />
                  As a product company, we love code.
                  <br />
                  <br />
                  And, strong technology is the backbone of everything we build
                  at Cashfree Payments.
                </p>
              </div>
              <div className="flex flex-wrap md:flex-nowrap justify-between md:justify-start mb-0  mt-7 md:mt-[40px]">
                <button
                  className="button button-green bg-cf-green w-full mb-4md md:mb-0 md:mr-4md  md:w-auto mt-2 md:mt-0 hover:brightness-110 active:brightness-110 focus:brightness-110"
                  type="button"
                  onClick={scrollToJob}>
                  Explore our tech roles <span className="chevron" />
                </button>

                <a
                  className="button button-outline no-shadow text-white border-cf-stroke w-full md:w-auto"
                  href="https://www.cashfree.com/blog/category/engineering/"
                  target="_blank"
                  rel="noopener noreferrer">
                  Engineering Blog <span className="chevron  !mb-0" />
                </a>
              </div>
            </div>
            <div className="w-full md:w-5/12 built-image relative">
              <img
                src="/img/careers/built.png"
                alt=""
                width="532"
                height="476"
                className="w-full mx-auto md:w-auto mt-10 md:mt-0 relative z-[1]"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="p-section-3 overflow-hidden bg-cf-light-grey">
        <div className="container">
          <div className="font-heading text-lg md:text-xl font-semibold text-left mb-2 md:mb-[60px]">
            We count on balance
          </div>
          <div className="flex flex-wrap items-stretch mx-[-8px] md:mx-[-19px]">
            {balance.map(({ id, description, heading, icon }) => (
              <div
                key={id}
                className="w-full md:w-1/4 pt-4md md:pt-0  md:px-[19px]">
                <div className="bg-white px-4md md:px-4md py-6md h-full rounded">
                  <div className="w-[32px] md:w-[40px] h-[32px] md:h-[40px] mb-4md">
                    <img
                      className="w-full h-full"
                      src={icon}
                      loading="lazy"
                      alt=""
                    />
                  </div>
                  <div>
                    <h3 className="font-body font-bold md:font-semibold mb-2md text-[16px]">
                      {heading}
                    </h3>
                    <p className="text-base">{description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="p-section-3 relative">
        <div className="container">
          <div className="flex flex-wrap bg-cf-hero md:justify-items-stretch">
            <div className="w-full h-[266px] md:h-auto md:w-1/2 relative md:pr-[19px]">
              <img
                src="/img/careers/fit.jpg"
                alt=""
                width="897"
                height="476"
                className="w-full h-full object-cover"
              />
            </div>
            <div className="w-full md:w-1/2 px-4 pb-[48px] md:pl-[19px] md:max-w-[500px] md:py-[60px]">
              <div className="text-white font-semibold font-heading text-2lg pt-4 md:pt-0">
                Don’t see a perfect&nbsp;fit?
              </div>
              <div className="text-cf-cold-purple pt-4 ">
                Let&apos;s find something that works for&nbsp;you.
              </div>
              <div className="text-cf-cold-purple pb-8 ">
                <a
                  href="mailto:careers@cashfree.com"
                  className="text-cf-green font-semibold">
                  Email us
                </a>{' '}
                your resume and anything that might help us understand your
                preference.
              </div>
              <a
                className="button button-green mx-auto w-full md:w-auto relative z-[1] md:min-w-[178px]"
                href="mailto:careers@cashfree.com">
                Email Us <span className="chevron" />
              </a>
            </div>
          </div>
        </div>
      </section>
      <GetStarted
        startNowLink="https://merchant.cashfree.com/merchants/signup?source-action=Careers&action=Sign%20Up&button-id=StartNow_Footer"
        getInTouchLink="https://www.cashfree.com/contact-sales/?source-action=Careers&action=Contact%20Sales&button-id=ContactSales_Footer"
      />
    </Layout>
  );
};

export default CareersPage;

/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import PropTypes from 'prop-types';
import './GallerySlider.scss';

const GallerySlider = ({ data, mobileData, imgUrl }) => {
  const container = useRef();
  const wrapper = useRef();
  const tl = useRef();
  useEffect(() => {
    const mm = gsap.matchMedia();
    mm.add('(min-width:768px)', () => {
      const tId = setTimeout(() => {
        tl.current = gsap.timeline({
          repeat: -1,
          defaults: {
            ease: 'none',
          },
        });
        const wrapWidth = wrapper.current.scrollWidth;

        tl.current.to('.wrapper', {
          duration: 30,
          ease: 'none',
          x: `-=${wrapWidth / 2}`,
        });
      }, 1000);
      return () => {
        tl.current?.kill();
        clearTimeout(tId);
      };
    });
    return () => {
      mm.kill();
    };
  }, []);
  return (
    <>
      <div
        className="gallery-slider overflow-hidden"
        ref={container}
        onMouseEnter={() => {
          tl.current?.pause();
        }}
        onMouseOut={() => tl.current?.resume()}>
        <div
          className="wrapper hidden md:flex flex-nowrap w-full"
          ref={wrapper}>
          {[...data, ...data].map((d, index) => (
            <div
              key={`${d.id}:${index}`}
              className="md:h-[518px] flex md:px-3.5  flex-shrink-0 item">
              <img
                className="w-full h-full object-cover rounded overflow-hidden"
                src={`${imgUrl}/${d.src}`}
                alt={d.alt}
                width={d.width}
                height={d.height}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="grid md:hidden grid-cols-2 gap-3md gallery-slider-mb mobile-container">
        {mobileData.map((d) => (
          <img
            className={`w-full h-full object-cover rounded overflow-hidden col-span-${
              d?.span ?? 1
            }`}
            key={d.id}
            src={`${imgUrl}/${d.src}`}
            alt={d.alt}
            width={d.width}
            height={d.height}
          />
        ))}
      </div>
    </>
  );
};
GallerySlider.defaultProps = {
  data: [],
  mobileData: [],
  imgUrl: '',
};
GallerySlider.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      src: PropTypes.string,
      width: PropTypes.string,
      height: PropTypes.string,
      alt: PropTypes.string,
    }),
  ),
  mobileData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      src: PropTypes.string,
      width: PropTypes.string,
      height: PropTypes.string,
      alt: PropTypes.string,
      span: PropTypes.number,
    }),
  ),
  imgUrl: PropTypes.string,
};
export default GallerySlider;

import React from 'react';
import PropTypes from 'prop-types';
import { RoundedTextInput } from '../Input';
import { defaultFilters } from '../../hooks/useJobs';
import Select from '../CaseStudyExplore/Select';

function JobInput({ filters, defaults, onChange }) {
  const onTextChange = (e) => {
    onChange('text', e.target.value);
  };
  const onDepartmentChange = (option) => {
    onChange('department', option?.value ?? '');
  };
  const onLocationChange = (option) => {
    onChange('location', option?.value ?? '');
  };
  return (
    <div className="flex flex-wrap lg:mx-[-19px]">
      <div className="w-full lg:w-1/2 lg:px-[19px] mb-3 lg:mb-0">
        <RoundedTextInput
          className="md:min-w-[290px]"
          placeholder="Search"
          clearEnabled
          onChange={onTextChange}
          value={filters?.text}
        />
      </div>
      <div className="w-1/2 lg:w-1/4 pr-[8px] lg:px-[19px]">
        <Select
          className="w-full font-medium"
          id="department"
          placeholder="Department"
          options={defaults.department}
          isClearable
          onChange={onDepartmentChange}
        />
      </div>
      <div className="w-1/2 lg:w-1/4 pl-[8px] lg:px-[19px]">
        <Select
          className="w-full font-medium"
          placeholder="Office Location"
          id="office-location"
          options={defaults.location}
          isClearable
          onChange={onLocationChange}
        />
      </div>
    </div>
  );
}
JobInput.defaultProps = {
  filters: defaultFilters,
  defaults: {
    department: [],
    location: [],
  },
  onChange: () => {},
};
JobInput.propTypes = {
  filters: PropTypes.shape({
    text: PropTypes.string,
    department: PropTypes.string,
    location: PropTypes.string,
  }),
  defaults: PropTypes.shape({
    department: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
    location: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
  }),
  onChange: PropTypes.func,
};
export default JobInput;

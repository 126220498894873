import React from 'react';
import PropTypes from 'prop-types';

function JobList({ data, className, loading, ...rest }) {
  if (!data.length) {
    return (
      <div className="mt-[36px] text-center font-medium text-4md">
        {loading ? 'Loading' : 'No result Found'}
      </div>
    );
  }
  return (
    <ul className={`flex flex-col job-list ${className}`} {...rest}>
      {(data || []).map((j) => (
        <li key={j.id} className="w-full border-b border-b-cf-stroke">
          <a
            href={j.hostedUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="flex flex-wrap py-[24px] group lg:mx-[-19px]">
            <div className="w-full md:w-1/2 pb-4 md:pb-0 md:pr-[19px] md:pl-[35px] font-semibold text-[16px] group-hover:text-cf-green group-focus:text-cf-green group-active:text-cf-green">
              {j.title}
            </div>
            <div className="w-1/2 md:w-1/4 md:pr-[19px] md:pl-[35px]">
              {j.department}
            </div>
            <div className="w-1/2 md:w-1/4 md:pr-[19px] md:pl-[35px] flex items-center">
              {/* <div className="w-[30px] h-[20px] inline-block mr-[9px]">
                <img src={`/img/flags/${j.country}.svg`} alt={j.country} />
              </div>{' '} */}
              {j.isRemote && j.location
                ? j.location
                : `${j.location}, ${j.country}`}
            </div>
          </a>
        </li>
      ))}
    </ul>
  );
}
JobList.defaultProps = {
  data: [],
  loading: false,
  className: '',
};
JobList.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      department: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      location: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      country: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    }),
  ),
};
export default JobList;
